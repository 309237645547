import axios from 'axios';
import { type ClassValue, clsx } from 'clsx'
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getParam(route?: RouteLocationNormalizedLoaded): string {
  if (route && route.params && 'domain' in route.params) {
    return route.params.domain as string;
  }
  return '';
}

export function clearLocalStorage() {
    localStorage.removeItem('doctor');
    localStorage.removeItem('appointment');
    localStorage.removeItem('reschedule');
}

const formatTimePart = (part: number): string => {
  return part.toString().padStart(2, '0');
};

export function bucketTime(time: string): string {
  const [hour, minute] = time.split(':').map(Number);
  const minuteBucket = minute - (minute % 15);
  return `${formatTimePart(hour)}:${formatTimePart(minuteBucket)}`;
}

/**
 * Delay for a given number of milliseconds
 * @param ms - The number of milliseconds to delay
 * @returns A promise that resolves after the delay
 */
export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function updateAxiosAuthHeader(token: string) {
  axios.defaults.headers['authorization'] = `Bearer ${token}`;
}

/**
 * Convert a date string from the server to a local date
 * @param dateStr - The date string to convert in the format "yyyy-MM-dd HH:mm:ss"
 * @returns The local date
 */
export function convertServerDateToLocalDate(dateStr: string): Date {
    const date = DateTime.fromFormat(dateStr, "yyyy-MM-dd HH:mm:ss", {
        zone: "America/Vancouver",
    })
    return date.toJSDate()
}