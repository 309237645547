<script setup lang="ts">
import { ref, watch } from 'vue';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import axios from 'axios';
import {
    Collapsible,
    CollapsibleContent
} from '@/components/ui/collapsible';

const isOpen = ref(true);
const postal = ref('');
const pharmacies = ref([] as any);
const tryAgain = ref(false);
const invalidPostalCode = ref(false);
const isFetching = ref(false);

function formatPharmacyData(data: any[]) {
    return data.map(item => ({
        ...item,
        distance: item.distance ?? ''
    }));
}

function getPharmacies(options: { basedOnPostal?: boolean } = { basedOnPostal: false }) {

    if (isFetching.value) {
        return;
    }

    isFetching.value = true;
    tryAgain.value = false;
    invalidPostalCode.value = false;
    pharmacies.value = [];

    let params = {};

    if (options.basedOnPostal) { 
        params = { postalCode: postal.value };
    }

    axios.post('https://book.avee.health/getProvCityFromPostal', params).then((res: any) => {

        if (res.status == 1) {
            pharmacies.value = formatPharmacyData(res.data);
        } else if (res.status == 2) {
            invalidPostalCode.value = true;
        } else {
            tryAgain.value = true;
        }

    }).catch(() => {
        tryAgain.value = true;
    }).finally(() => {
        isFetching.value = false;
    });

}

watch(postal, (newValue, oldValue) => {
    const sanitizedPostal = newValue.replace(' ', '');
    
    if (sanitizedPostal !== oldValue && sanitizedPostal.length === 6) {
        postal.value = sanitizedPostal;
    }
});
</script>

<template>
<div>
    <img src="/Logo-01.png" width="80" class="mb-5">
    <h1 class="text-4xl mb-4 font-bold">Get a Virtual Doctor Appointment in BC, Canada</h1>
    <h2 class="p-description text-2xl font-extralight mb-7">from Your Preferred Pharmacy</h2>
    <Card class="p-4">
        <Label for="postal">Enter your postal code to find the nearest pharmacies</Label>
        <Input id="postal" type="text" placeholder="Enter Postal Code" v-model:model-value="postal" class="mt-1.5"/>
        <div class="flex mt-4">
            <Button @click="getPharmacies({basedOnPostal: true})" :disabled="isFetching">Search</Button>
            <Button variant="outline" @click="getPharmacies()" class="ml-2.5" :disabled="isFetching">Show All Pharmacies</Button>
        </div>
        <Collapsible v-model:open="isOpen" class="mt-5">
            <CollapsibleContent>
                <a v-for="item in pharmacies" :href="item.href" class="p-4 mb-2.5 bg-gray-50 block text-gray-700 border rounded-md">
                    <div class="flex justify-between text-md">
                        <span class="font-bold">{{ item.name }}</span>
                        <span>{{item.distance}}</span>
                    </div>
                    <div class="mt-2.5 text-sm">{{ item.address }}</div>
                </a>
            </CollapsibleContent>
        </Collapsible>
        <div v-if="tryAgain" class="mt-5 font-medium p-7 text-center">
            <div>Oops! Something Went Wrong</div>
            <div>Please try again or see all pharmacies.</div>
            <Button class="mt-2.5" @click="getPharmacies()" :disabled="isFetching">Try Again</Button>
        </div>
        <div v-if="invalidPostalCode" class="mt-5 font-medium p-7 text-center">
            <div>Invalid Postal Code</div>
            <div>Please enter a valid postal code and try again.</div>
        </div>
    </Card>
</div>
</template>

<style>
.p-pharmacy {
        border: 1px solid lightgray;
        border-radius: .5rem;
    }
</style>
