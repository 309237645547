<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { store } from '@/store/store'
import Header from './components/shared/Header.vue'
import Toaster from '@/components/ui/toast/Toaster.vue'

const windowWidth = ref(0)

onMounted(() => {
    windowWidth.value = window.innerWidth
    window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth
    })
})
</script>

<template>
  <div class="wrapper first-page bg-gray-50">
    <div class="container min-h-screen" 
    :class="{ 'max-w-[580px]': $route.name == 'home', 'flex justify-center items-center': $route.name == 'success' || $route.name == 'failed_pay' }">
      <div class="flex w-full">
        <div class="grow relative lg:ml-3">
          <div v-if="$route.name == 'times' && store.selectedDoctor.image" class="doctor-img-container absolute -top-5 h-[393px]">
            <img :src="store.selectedDoctor.image" :alt="store.selectedDoctor.name" class="doctor-img absolute w-full h-full object-cover backdrop-blur-xs">
            <div class="doctor-img-cover h-full w-full absolute"></div>
          </div>
          <Header 
          v-show="$route.name != 'home' && $route.name != 'NotFound' && $route.name != 'success' && $route.name != 'failed_pay' && $route.name != 'manage_appointment' && $route.name != 'pharmacy'"
          :class="{'mb-[230px]': $route.name == 'times' && store.selectedDoctor.image && windowWidth < 1200}"/>
          <div class="content-wrapper flex justify-center" :class="{'pt-[230px]': $route.name == 'times' && store.selectedDoctor.image && windowWidth >= 1200}">
            <div class="relative max-w-[394px]" style="width: calc(100vw - 40px);">
              <router-view v-slot="{ Component }">
                <keep-alive exclude="Verify,Details,Pay">
                  <component :is="Component" />
                </keep-alive>
              </router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toaster />
</template>

<style>
.wrapper {
  position: relative;
  min-height: 100vh;
}

.wrapper::before {
  content: '';
  position: absolute;
  background-image: url('/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  height: 300px;
}

.container {
  position: relative;
  padding: 1.25rem;
}

.doctor-img-container {
  width: calc(100% + 20px);
}

.doctor-img-cover {
  background: linear-gradient(transparent, #FAF8F5);
}

@media (min-width: 640px) {
  .wrapper::before {
    height: 100%;
  }
}

@media (max-width: 1200px) {
  .doctor-img-container {
    left: -20px;
    width: calc(100% + 40px);
  }
}
</style>
