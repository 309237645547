<script setup lang="ts">
import { store } from '@/store/store'
import { Button } from '@/components/ui/button'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { vOnClickOutside } from '@vueuse/components'
import type { OnClickOutsideHandler } from '@vueuse/core'
import axios from 'axios';
import { clearLocalStorage, getParam } from '@/lib/utils';
import { SquareUserRound, LogOut, FolderOpen } from 'lucide-vue-next';

const route = useRoute();
const router = useRouter();
const menu = ref(false);

function logout() {
    localStorage.removeItem('token');
    clearLocalStorage();
    axios.defaults.headers['authorization'] = '';

    let redirectUrl = '/'
    if (route.params.domain) {
        redirectUrl += route.params.domain;
    }

    window.location.href = redirectUrl;
}

function changePatient() {
    menu.value = false;
    router.push({ name: 'who', params: { domain: getParam(route) } });
}

const onClickOutsideHandler: OnClickOutsideHandler = () => {
    menu.value = false;
}
</script>

<template>
    <div>
        <div v-if="store.loginFlag && route.name != 'who'" class="relative">
            <Button variant="ghost" class="border relative bg-white" @click="menu = !menu">
                <SquareUserRound class="size-4 mr-2" />
                {{ store.selectedPatient?.name + ' ' + store.selectedPatient?.family }}
            </Button>

            <div v-if="menu" class="absolute top-full right-0 pt-2 flex flex-col gap-2 cursor-pointer z-30"
                v-on-click-outside="onClickOutsideHandler">
                <Button variant="outline" @click="changePatient" class="cursor-pointer">
                    <FolderOpen class="size-4 mr-2" />
                    Change Patient
                </Button>
                <Button variant="outline" @click="logout" class="cursor-pointer">
                    <LogOut class="size-4 mr-2 rotate-180" />
                    Logout
                </Button>
            </div>
        </div>
        <Button v-if="['login', 'address', 'who'].includes(route.name as string)" variant="ghost"
            class="border bg-white cursor-pointer" @click="logout">
            Logout
        </Button>
    </div>
</template>
