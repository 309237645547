import Pharmacy from '@/pages/Pharmacy.vue';
import Book from '@/pages/Book.vue'
import Phone from '@/pages/Phone.vue'
import Verify from '@/pages/Verify.vue'
import Login from '@/pages/Login.vue'
import Address from '@/pages/Address.vue'
import Who from '@/pages/Who.vue'
import Add from '@/pages/Add.vue'
import Doctors from '@/pages/Doctors.vue'
import Times from '@/pages/Times.vue'
import Details from '@/pages/Details.vue'
import Pharmacies from '@/pages/Pharmacies.vue'
import Pay from '@/pages/Pay.vue'
import Success from '@/pages/Success.vue'
import FailedPay from '@/pages/FailedPay.vue'
import ManageAppointment from '@/pages/ManageAppointment.vue'
import NotFound from '@/pages/NotFound.vue'

const routes = [
    { path: '/pharmacy', name: 'pharmacy', component: Pharmacy },
    { path: '/:domain([\\w-]+)?', name: 'home', component: Book },
    { path: '/:domain([\\w-]+)?/phone', name: 'phone', component: Phone, meta: { backPath: 'home' } },
    { path: '/:domain([\\w-]+)?/verify', name: 'verify', component: Verify, meta: { backPath: 'phone' } },
    { path: '/:domain([\\w-]+)?/login', name: 'login', component: Login },
    { path: '/:domain([\\w-]+)?/address', name: 'address', component: Address, meta: { backPath: 'login' } },
    { path: '/:domain([\\w-]+)?/who', name: 'who', component: Who },
    { path: '/:domain([\\w-]+)?/add', name: 'add', component: Add, meta: { backPath: 'who' } },
    { path: '/:domain([\\w-]+)?/details', name: 'details', component: Details, meta: { backPath: 'who' } },
    { path: '/:domain([\\w-]+)?/doctors', name: 'doctors', component: Doctors, meta: { backPath: 'details' } },
    { path: '/:domain([\\w-]+)?/times', name: 'times', component: Times, meta: { backPath: 'doctors' } },
    { path: '/:domain([\\w-]+)?/pharmacies', name: 'pharmacies', component: Pharmacies, meta: { backPath: 'times' } },
    { path: '/:domain([\\w-]+)?/pay', name: 'pay', component: Pay },
    { path: '/:domain([\\w-]+)?/success', name: 'success', component: Success },
    { path: '/:domain([\\w-]+)?/failed_pay', name: 'failed_pay', component: FailedPay },
    { path: '/:domain([\\w-]+)?/manage_appointment', name: 'manage_appointment', component: ManageAppointment },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

export default routes;
