<script setup lang="ts">
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '@/components/ui/form';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as z from 'zod';
import { vAutoAnimate } from '@formkit/auto-animate/vue';
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { store } from '@/store/store';
import axios from 'axios';
import { useToast } from '@/components/ui/toast/use-toast';
import { Loader2 } from 'lucide-vue-next';
import { clearLocalStorage, getParam, updateAxiosAuthHeader } from '@/lib/utils';
import { useGooglePlaces } from '@/lib/useGooglePlaces'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

const { toast } = useToast();
const route = useRoute();
const router = useRouter();
const loader = ref(false);

const {
    country,
    province,
    city,
    postal_code,
    address
} = useGooglePlaces();

const formSchema = toTypedSchema(z.object({
    province: z.string().min(1, { message: "Required" }),
    address: z.string().min(1, { message: "Required" }),
    city: z.string().min(1, { message: "Required" }),
    postal_code: z.string()
        .min(1, { message: "Required" })
        .refine((val) => {
            if (country.value === 'ca') {
                return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(val);
            } else if (country.value === 'us') {
                return /^\d{5}(-\d{4})?$/.test(val);
            }
            return true;
        }, { message: "Invalid postal/ZIP code format" })
}));

const { handleSubmit } = useForm({
    validationSchema: formSchema,
});

const onSubmit = handleSubmit(() => {
    if (loader.value) {
        return;
    }

    loader.value = true;
    storeNewPatient();

    axios.post('/userRegister', store.newPatient).then((res: any) => {
        
        if (res.status === 'success') {
            const registerToken = res.data.token;
            localStorage.setItem('registerToken', registerToken);
            updateAxiosAuthHeader(registerToken);

            const params = {
                token: registerToken,
                // pid: res.data.pid
            }

            axios.post('/refreshToken', params).then((res2: any) => {

                if (res2.status === 'success') {
                    const newToken = res2.data.token;
                    localStorage.setItem('token', newToken);
                    updateAxiosAuthHeader(newToken);

                    store.members = res2.data.people;
                    store.selectedPatient = res.data;
                    store.login();
                    clearLocalStorage();

                    if (store.redirect_page.fullPath) {
                        loader.value = false;
                        const page = store.redirect_page;
                        store.redirect_page = {
                            fullPath: '',
                            name: '',
                            query: {} as any
                        };
                        router.push({ name: page.name, query: page.query, params: { domain: getParam(route) } });
                        return;
                    }
                    setTimeout(function () {
                        loader.value = false;
                        router.push({ name: 'who', params: { domain: getParam(route) } });
                    }, 300);
                } else {
                    toast({
                        title: res2.msg ?? DEFAULT_ERROR_MESSAGE,
                        variant: 'destructive'
                    });
                    loader.value = false;
                }
            });
        } else {
            toast({
                variant: 'destructive',
                title: res.msg ?? DEFAULT_ERROR_MESSAGE
            });
            loader.value = false;
        }

    });
});

function storeNewPatient() {
    store.newPatient.province = province.value;
    store.newPatient.address = address.value;
    store.newPatient.city = city.value;
    store.newPatient.postal_code = postal_code.value;

    const patientPhone = localStorage.getItem('phone');
    if (patientPhone) {
        store.newPatient.phone = patientPhone;
    }
}

onMounted(() => {
    // if the user reaches this page, we must make sure that the previous steps are complete
    // otherwise, redirect to the previous step.
    // this implementation isn't ideal because we're assuming that certain fields
    // must be filled in and if someone wants to add a field in the future, they
    // must update this code too. however, it's a quick and dirty solution.
    if (route.name !== 'address') {
        return;
    }

    const {
        name,
        family, 
        gender,
        birthday
    } = store.newPatient

    const isEmpty = (item: string) => !item || item.length === 0;

    const previousStepIsIncomplete = [name, family, gender, birthday].some(isEmpty);
    if (previousStepIsIncomplete) {
        router.push({ name: 'login', params: { domain: getParam(route) } });
    }
})
</script>

<template>
    <h2 class="title">Where do you live?</h2>
    <div class="mt-1 text-gray-600">We need your address to create your medical record.</div>
    <form @submit="onSubmit" class="grid gap-7 mt-10">
        <FormField v-slot="{ componentField }" name="province" v-model:model-value="province" :validate-on-model-update="true">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="province">Province</Label>
                        <Select id="province" v-bind="componentField">
                            <SelectTrigger>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="BC">British Columbia</SelectItem>
                                <SelectItem value="ON">Ontario</SelectItem>
                                <SelectItem value="MB">Manitoba</SelectItem>
                                <SelectItem value="NU">Nunavut</SelectItem>
                                <SelectItem value="QC">Quebec</SelectItem>
                                <SelectItem value="NT">Northwest Territories</SelectItem>
                                <SelectItem value="AB">Alberta</SelectItem>
                                <SelectItem value="SK">Saskatchewan</SelectItem>
                                <SelectItem value="YT">Yukon</SelectItem>
                                <SelectItem value="NL">Newfoundland and Labrador</SelectItem>
                                <SelectItem value="NB">New Brunswick</SelectItem>
                                <SelectItem value="NS">Nova Scotia</SelectItem>
                                <SelectItem value="PE">Prince Edward Island</SelectItem>
                                <SelectItem value="US State">US State</SelectItem>
                                <SelectItem value="Outside Canada and USA">Outside Canada and USA</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="address" v-model:model-value="address">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="address">Address</Label>
                        <Input id="address" v-model:model-value="address" v-bind="componentField"/>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="city" v-model:model-value="city">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="city">City</Label>
                        <Input id="city" type="text" v-bind="componentField" />
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="postal_code" v-model:model-value="postal_code">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="postal_code">Postal Code</Label>
                        <Input id="postal_code" type="text" v-bind="componentField" />
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <Button type="submit" size="lg" class="font-bold w-full mt-10 text-lg" :disabled="loader" :class="{'opacity-30 cursor-not-allowed': !province || !address || !city || !postal_code}">
            <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
            Continue
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </Button>
    </form>
</template>
