<script lang="ts">
  export default {
    name: 'Pay'
  }
</script>

<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useToast } from '@/components/ui/toast/use-toast'
import { useDateValidation } from '@/lib/useDateValidation'
import { clearLocalStorage, delay, getParam } from '@/lib/utils'
import { store } from '@/store/store'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { toTypedSchema } from '@vee-validate/zod'
import { watchDebounced } from '@vueuse/core'
import axios from 'axios'
import { Loader2 } from 'lucide-vue-next'
import { useForm } from 'vee-validate'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import * as z from 'zod'

const { toast } = useToast()

const route = useRoute();
const router = useRouter();

const {
    year,
    month,
    day,
    daysInMonth,
    isFutureDate,
    yearRange
} = useDateValidation();

const disableEditingPhn = ref(false);
const paymentType = ref<'health-card' | 'pay' | 'insurance' | 'invalid-phn' | 'none'>('none')
const loaderPage = ref(true);

const formSchema = toTypedSchema(z.object({
    phn: z.string(),
    year: z.string(),
    month: z.string(),
    day: z.string()
        .refine((val) => {
            if (!year.value || !month.value || !val) return true;
            const maxDays = daysInMonth.value;
            return parseInt(val) <= maxDays;
        }, { message: "Invalid day for selected month" })
        .refine((val) => {
            if (!year.value || !month.value || !val) return true;
            return !isFutureDate(year.value, month.value, val);
        }, { message: "Date cannot be in the future" })
}))

const { handleSubmit } = useForm({
    validationSchema: formSchema,
})

const phn = ref('')
const error = ref(false)

function choosePaymentType(kind: typeof paymentType.value) {
    if (paymentType.value == 'insurance') {
        router.push({ name: 'details', params: { domain: getParam(route) } });
        return;
    }

    paymentType.value = kind;
}

function useCheckHIN() {
    const loaderCheckHIN = ref(false);

    async function checkHIN() {
        if (loaderCheckHIN.value) return;

        loaderCheckHIN.value = true;

        try {
            const hinResponse = await axios.post('/checkHin', { pid: store.appointment.pid }) as any;

            if (hinResponse.status == 'success') {
                const { data } = hinResponse;
                const { status, action } = data;

                if (status === 'valid') {
                    paymentType.value = 'insurance';
                } else if (status === 'invalid' && action === 'allow') {
                    paymentType.value = 'health-card';
                    phn.value = data.hin;
                    year.value = data.birthday.slice(0, 4);
                    month.value = data.birthday.slice(5, 7);
                    day.value = data.birthday.slice(8, 10);
                } else if (status === 'invalid' && action === 'notAllow') {
                    paymentType.value = 'invalid-phn';
                    phn.value = data.hin;
                    disableEditingPhn.value = true;
                } else {
                    paymentType.value = 'none'
                }
            } else {
                throw new Error(hinResponse.msg);
            }
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: error.message ?? 'An error occurred while checking your health card.',
                    variant: 'destructive'
                })
            }
        } finally {
            loaderCheckHIN.value = false;
        }
    }

    return {
        loader: loaderCheckHIN,
        checkHIN
    }
}

function useSetAppointment() {
    const loaderSetAppointment = ref(false);

    async function setAppointment(kind: 'bc' | 'nobc') {
        if (loaderSetAppointment.value) return;

        if (kind === 'bc') {
            store.appointment.phnType = 'bc'
            store.appointment.hin = phn.value
            store.appointment.birthday = year.value + '-' + month.value + '-' + day.value
        } else {
            store.appointment.phnType = 'nobc'
        }
        
        loaderSetAppointment.value = true;

        let code: number | null = null;

        try {
            const setAppointmentResponse = await axios.post('/setNewAppointment', store.appointment) as any;
            if (!setAppointmentResponse.code) {
                throw new Error('An error occurred while setting your appointment. Please try again.');
            }

            code = parseInt(setAppointmentResponse.code, 10);

            if (setAppointmentResponse.status !== 'success') {
                throw new Error(setAppointmentResponse.msg);
            }

            clearLocalStorage();

            const appointmentIsPrivate = code === 6
            const customerShouldPay = (kind == 'bc' && appointmentIsPrivate) || kind === 'nobc';

            if (customerShouldPay) {
                const checkoutParams = {
                    appID: setAppointmentResponse.data.appID,
                    successBackUrl: window.location.href.split('/pay')[0] + '/success',
                    errorBackUrl: window.location.href.split('/pay')[0] + '/failed_pay'
                }
                const checkoutResponse = await axios.post('/checkout', checkoutParams) as any;

                if (checkoutResponse.status !== 'success') {
                    throw new Error(checkoutResponse.msg);
                }

                if (!checkoutResponse.data?.url) {
                    throw new Error('Failed to connect to payment gateway. Please try again.');
                }
                
                window.location.href = checkoutResponse.data.url
            } else {
                await delay(300)
                router.push({
                    name: 'success', params: { domain: getParam(route) }, query: {
                        appID: setAppointmentResponse.data.appID
                    }
                })
            }
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: error.message ?? 'An error occurred while setting your appointment.',
                    variant: 'destructive'
                })
                
                const failedBecauseHINIsInvalid = code === 102;
                if (!failedBecauseHINIsInvalid) {
                    router.push({ name: 'times', params: { domain: getParam(route) } });
                }
            }
        } finally {
            loaderSetAppointment.value = false;
        }
    }

    return {
        loader: loaderSetAppointment,
        setAppointment
    }
}

const { loader: loaderCheckHIN, checkHIN } = useCheckHIN();
const { loader: loaderSetAppointment, setAppointment } = useSetAppointment();

const onSubmit = handleSubmit(() => {
    if (!loaderSetAppointment.value) {
        setAppointment('bc');
    }
});

function goNext() {
    if (!loaderSetAppointment.value) {
        setAppointment('nobc');
    }
}

// compute value of loaderPage based on other loaders
watchDebounced([loaderCheckHIN], () => {
    loaderPage.value = loaderCheckHIN.value || loaderSetAppointment.value
}, { debounce: 100 });

onMounted(async () => {
    const localInfo = JSON.parse(localStorage.getItem('appointment') ?? '');
    if (!localInfo) {
        router.push({ name: 'who', params: { domain: getParam(route) } });
        return;
    }

    if (store.clinic.id) {
        store.appointment.cid = store.clinic.id;
        store.appointment.clinicID = store.clinic.id;
    }
    
    if (store.appointment.pid) {
        loaderPage.value = true;
        await checkHIN();

        if (paymentType.value === 'insurance') {
            setAppointment('bc');
        }
    } else {
        router.push({ name: 'who', params: { domain: getParam(route) } });
    }
});
</script>

<template>
    <div v-if="loaderPage" class="mt-16">
        <Loader2 class="w-10 h-10 mx-auto animate-spin " />
        <div class="mt-3 text-gray-500 text-center">Verifying Your Health Card...</div>
    </div>
    <div v-else-if="paymentType === 'invalid-phn'">
        <h2 class="title">Health Card Verification Failed!</h2>
        <div class="mt-1 text-gray-600">We’re sorry, but your health card was not accepted by MSP.
            Please contact MSP for more details.
            <p class="mt-3">To continue your visit, kindly proceed with the $39 payment.</p>
            <div class="mt-10">
                <Label for="showHIN">Health Card Number (non-editable)</Label>
                <Input id="showHIN" disabled :placeholder="phn" class="mt-1.5" />
            </div>
            <Button size="lg" class="font-bold w-full text-lg mt-10" @click="goNext" :disabled="loaderSetAppointment">
                <Loader2 v-if="loaderSetAppointment" class="w-4 h-4 mr-2 animate-spin" />
                Pay
                <span class="ml-1 font-normal">(${{ parseFloat(store.selectedDoctor.price.price) + ' ' +
                    store.selectedDoctor.price.currency }})</span>
                <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
            </Button>
        </div>
    </div>
    <div v-else>
        <h2 class="title">Do you have Health Card?</h2>
        <div class="mt-1 text-gray-600">If you have a BC Personal Health Number, our service is free.</div>
        <div class="mt-10 grid gap-3">
            <Card class="p-3 cursor-pointer"
                :class="{'border-2 border-primary bg-cyan-50': paymentType === 'health-card'}"
                @click="choosePaymentType('health-card')">
                <div class="font-medium text-gray-950">Yes. I have a BC Health Card.</div>
                <div class="mt-1 text-sm text-gray-400">Free</div>
            </Card>
            <Card class="p-3 cursor-pointer"
                :class="{'border-2 border-primary bg-cyan-50': paymentType === 'pay', 'cursor-not-allowed opacity-60': paymentType === 'insurance'}"
                @click="choosePaymentType('pay')">
                <div class="font-medium text-gray-950">No, I will pay for the visit</div>
                <div class="mt-1 text-sm text-gray-400">${{ parseFloat(store.selectedDoctor.price.price) + ' ' +
                    store.selectedDoctor.price.currency }}</div>
            </Card>
        </div>
        <form @submit="onSubmit" v-show="paymentType === 'health-card'" class="mt-10 grid gap-7">
            <h3 class="text-2xl font-bold text-gray-900">Enter your Health Card Info</h3>
            <FormField v-slot="{ componentField }" name="phn" v-model:model-value="phn">
                <FormItem v-auto-animate>
                    <FormControl>
                        <div class="grid gap-1.5">
                            <Label for="phn">Health Card Number (MSP)</Label>
                            <Input id="phn" type="text" v-bind="componentField" :disabled="disableEditingPhn" />
                        </div>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            </FormField>
            <div class="grid gap-1.5">
                <Label>Date of birth</Label>
                <div class="date-container">
                    <div class="w-[30%] grow-0">
                        <FormField v-slot="{ componentField }" name="year" v-model:model-value="year"
                            :validate-on-model-update="true">
                            <FormItem>
                                <FormControl>
                                    <Select id="year" v-bind="componentField" :disabled="disableEditingPhn">
                                        <SelectTrigger>
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem v-for="year in yearRange" :key="year" :value="String(year)">
                                                {{ year }}
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="w-[40%] grow-0">
                        <FormField v-slot="{ componentField }" name="month" v-model:model-value="month"
                            :validate-on-model-update="true" >
                            <FormItem >
                                <FormControl >
                                    <Select id="month" v-bind="componentField" :disabled="disableEditingPhn"
                                        >
                                        <SelectTrigger>
                                            <SelectValue class="w-full" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem v-for="n in 12" :value="String(n).padStart(2, '0')" :key="n">
                                                {{ String(n).padStart(2, '0') + ' - ' + store.months[n-1] }}
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="w-[30%] grow-0">
                        <FormField v-slot="{ componentField }" name="day" v-model:model-value="day"
                            :validate-on-model-update="true">
                            <FormItem v-auto-animate>
                                <FormControl>
                                    <Select id="day" v-bind="componentField" :disabled="disableEditingPhn">
                                        <SelectTrigger>
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem v-for="n in daysInMonth" :value="String(n).padStart(2, '0')">{{
                                                String(n).padStart(2, '0') }}</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                </div>
            </div>
            <Button type="submit" size="lg" class="font-bold w-full mt-10 text-lg" :disabled="loaderSetAppointment"
                :class="{'opacity-30 cursor-not-allowed': !phn || !year || !month || !day}">
                <Loader2 v-if="loaderSetAppointment" class="w-4 h-4 mr-2 animate-spin" />
                Done
                <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
            </Button>
            <div v-if="error" class="p-3 rounded-sm bg-red-300 text-red-950 font-medium">The health card number and DOB
                you've entered are not valid. Please try again or choose to pay for the visit.</div>
        </form>
        <div v-if="paymentType === 'pay'" class="mt-10 grid gap-7">
            <Button size="lg" class="font-bold w-full text-lg" @click="goNext" :disabled="loaderSetAppointment">
                <Loader2 v-if="loaderSetAppointment" class="w-4 h-4 mr-2 animate-spin" />
                Pay
                <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
            </Button>
        </div>
    </div>
</template>
