import { reactive } from 'vue'

export const store = reactive({
    support_phone: '(604) 484-0637',
    clinic: {
        id: '',
        type: '',
        name: '',
        firstAvailableTime: '',
        logo: '',
        days: [] as string[]
    },
    uniq: '',
    loginFlag: false,
    phone: '',
    members: [] as any,
    link: '',
    selectedPatient: {
        pid: '',
        name: '',
        family: ''
    },
    newPatient: {
        name: '',
        family: '',
        gender: '',
        birthday: '',
        province: '',
        address: '',
        city: '',
        postal_code: '',
        phone: ''
    },
    selectedDoctor: {
        id: '',
        image: '',
        name: '',
        days: [] as string[],
        price: {} as any,
        first_available: true
    },
    appointment: {
        pid: '',
        doctorID: '',
        time: '',
        day: '',
        note: '',
        file: '' as any,
        duration: 5,
        cid: '',
        clinicID: '',
        doFrom: 'patient',
        bookChannel: 'online_booking',
        phnType: '',
        preferredPharmacy: {
            clinicID: '',
            clinicName: '',
            clinicCity: '',
            clinicFax: '',
            clinicAddress: '',
            clinicPostal: '',
        },
        diseases: '',
        source: '',
        hin: '',
        birthday: '',
        appID: '',
        save: false
    },
    app: {} as any,
    login() {
        this.loginFlag = true
    },
    logout() {
        this.loginFlag = false
    },
    doctor_phone: '(604) 762-0537',
    redirect_page: {
        fullPath: '',
        name: '',
        query: {} as any
    },
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
})
