<script setup lang="ts">
import { onActivated, ref, watch } from 'vue' 
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-vue-next'
import { useRouter, useRoute } from 'vue-router'
import { store } from '@/store/store'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { useToast } from '@/components/ui/toast/use-toast'
import axios from 'axios'
import { clearLocalStorage, getParam, updateAxiosAuthHeader } from '@/lib/utils';
import { useDateValidation } from '@/lib/useDateValidation';
import { useGooglePlaces } from '@/lib/useGooglePlaces'

const { toast } = useToast()

const route = useRoute();
const router = useRouter();

const sameAddress = ref(false);
const terms = ref(false);
const name = ref('')
const family = ref('')
const gender = ref('')
const { 
    year, 
    month, 
    day, 
    daysInMonth, 
    isFutureDate, 
    yearRange 
} = useDateValidation()
const relationship = ref('')
const {
    province,
    city,
    postal_code,
    address
} = useGooglePlaces()
const loader = ref(false)

const formSchema = toTypedSchema(z.object({
    name: z.string().min(3, {message: 'Minimum 3 characters.'}),
    family: z.string().min(3, {message: 'Minimum 3 characters.'}),
    gender: z.string(),
    year: z.string(),
    month: z.string(),
    day: z.string()
        .refine((val) => {
            if (!year.value || !month.value || !val) return true;
            const maxDays = daysInMonth.value;
            return parseInt(val) <= maxDays;
        }, { message: "Invalid day for selected month" })
        .refine((val) => {
            if (!year.value || !month.value || !val) return true;
            return !isFutureDate(year.value, month.value, val);
        }, { message: "Date cannot be in the future" })
}))

const { handleSubmit, resetForm } = useForm({
  validationSchema: formSchema,
})

const onSubmit = handleSubmit(() => {

    if (!loader.value && terms.value) {

        if (!province.value || !city.value || !postal_code.value) {
            toast({
                title: 'Specify the address.',
                variant: 'destructive'
            });
            return;
        }

        let values = {
            name: name.value,
            address: address.value,
            family: family.value,
            gender: gender.value,
            birthday: year.value + '-' + month.value + '-' + day.value,
            relationship: relationship.value,
            province: province.value,
            city: city.value,
            postal_code: postal_code.value,
            phone: store.phone || localStorage.getItem('phone')
        };

        loader.value = true;
        axios.post('/addPatient', values).then((patientInfoResponse: any) => {

            if (patientInfoResponse.status == 'success') {
                const newlyAddedPatient = patientInfoResponse.data;

                let params = {
                    token: localStorage.getItem('token'),
                    pid: newlyAddedPatient.pid
                }

                axios.post('/refreshToken', params).then((tokenResponse: any) => {

                    if (tokenResponse.status == 'success') {
                        const newToken = tokenResponse.data.token;
                        localStorage.setItem('token', newToken);
                        updateAxiosAuthHeader(newToken);
                        
                        store.selectedPatient = newlyAddedPatient;
                        store.appointment.pid = newlyAddedPatient.pid;
                        store.members = [
                            // make sure the patient is not already in the list
                            ...store.members.filter(member => member.pid !== newlyAddedPatient.pid),
                            newlyAddedPatient,
                        ]

                        store.login();
                        clearLocalStorage();

                        setTimeout(function () {
                            loader.value = false
                            router.push({ name: 'details', params: { domain: getParam(route) } })
                        }, 300);

                    }

                });

            } else {

                loader.value = false
                toast({
                    title: patientInfoResponse.msg,
                    variant: 'destructive'
                })

            }
        });

    }


});

watch(sameAddress, function (val) {
    if (val) {
        axios.post('getAddressByPid', { pid: store.selectedPatient.pid }).then((res: any) => {
            if (res.status == 'success') {
                let info = res.data
                if (info.province.length == 2) {
                    province.value = info.province.toUpperCase();
                } else {
                    province.value = info.province
                }
                address.value = info.patientAddress
                city.value = info.city
                postal_code.value = info.postal
            }
        })
    }
})

onActivated(() => {
    resetForm();
    relationship.value = '';
    province.value = '';
    city.value = '';
    postal_code.value = '';
    address.value = '';
    terms.value = false;
    sameAddress.value = false;
});
</script>

<template>
    <h2 class="title">Who would you like to add?</h2>
    <form @submit="onSubmit" class="grid gap-7 mt-10">
        <FormField v-slot="{ componentField }" name="name">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="name">First name</Label>
                        <Input id="name" type="text" v-model:model-value="name" v-bind="componentField"/>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="family">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="family">Last name</Label>
                        <Input id="family" type="text" v-model:model-value="family" v-bind="componentField"/>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="gender" :validate-on-model-update="true">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="gender">Sex assigned at birth</Label>
                        <Select id="gender" v-model:model-value="gender" v-bind="componentField">
                            <SelectTrigger>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="M">Male</SelectItem>
                                <SelectItem value="F">Female</SelectItem>
                                <SelectItem value="I">Intersex</SelectItem>
                                <SelectItem value="Not">Prefer not to say</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <div class="grid gap-1.5">
            <Label>Date of birth</Label>
            <div class="date-container">
                <div class="w-[30%]">
                    <FormField v-slot="{ componentField }" name="year" :validate-on-model-update="true">
                        <FormItem v-auto-animate>
                            <FormControl>
                                <Select id="year" v-model:model-value="year" v-bind="componentField">
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem 
                                            v-for="year in yearRange" 
                                            :key="year"
                                            :value="String(year)"
                                        >
                                            {{ year }}
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>
                <div class="w-[40%]">
                    <FormField v-slot="{ componentField }" name="month" :validate-on-model-update="true">
                        <FormItem v-auto-animate>
                            <FormControl>
                                <Select id="month" v-model:model-value="month" v-bind="componentField">
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem 
                                            v-for="n in 12" 
                                            :value="String(n).padStart(2, '0')"
                                            :key="n"
                                        >
                                            {{ String(n).padStart(2, '0') + ' - ' + store.months[n-1] }}
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>
                <div class="w-[30%]">
                    <FormField v-slot="{ componentField }" name="day" :validate-on-model-update="true">
                        <FormItem v-auto-animate>
                            <FormControl>
                                <Select id="day" v-model:model-value="day" v-bind="componentField" class="w-full">
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem v-for="n in daysInMonth" :value="String(n).padStart(2, '0')">{{ String(n).padStart(2, '0') }}</SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>
            </div>
        </div>
        <div class="grid gap-1.5">
            <Label for="relationship">Relationship to you</Label>
            <Select id="relationship" class="w-full" v-model:model-value="relationship">
                <SelectTrigger>
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="spouse">Spouse</SelectItem>
                    <SelectItem value="child">Child</SelectItem>
                    <SelectItem value="sibling">Sibling</SelectItem>
                    <SelectItem value="parent">Parent</SelectItem>
                    <SelectItem value="grandparent">Grandparent</SelectItem>
                    <SelectItem value="other">Other</SelectItem>
                </SelectContent>
            </Select>
        </div>
        <div class="items-top flex space-x-2">            
            <Checkbox id="same_address" v-model:checked="sameAddress"/>
            <Label for="same_address">Same address with my address</Label>
        </div>
        <div class="grid gap-7">
            <div class="grid gap-1.5">
                <Label for="province">Province</Label>
                <Select id="province" v-model:model-value="province">
                    <SelectTrigger>
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="BC">British Columbia</SelectItem>
                        <SelectItem value="ON">Ontario</SelectItem>
                        <SelectItem value="MB">Manitoba</SelectItem>
                        <SelectItem value="NU">Nunavut</SelectItem>
                        <SelectItem value="QC">Quebec</SelectItem>
                        <SelectItem value="NT">Northwest Territories</SelectItem>
                        <SelectItem value="AB">Alberta</SelectItem>
                        <SelectItem value="SK">Saskatchewan</SelectItem>
                        <SelectItem value="YT">Yukon</SelectItem>
                        <SelectItem value="NL">Newfoundland and Labrador</SelectItem>
                        <SelectItem value="NB">New Brunswick</SelectItem>
                        <SelectItem value="NS">Nova Scotia</SelectItem>
                        <SelectItem value="PE">Prince Edward Island</SelectItem>
                        <SelectItem value="US State">US State</SelectItem>
                        <SelectItem value="Outside Canada and USA">Outside Canada and USA</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div class="grid gap-1.5">
                <Label for="address">Address</Label>
                <Input id="address" v-model:model-value="address"/>
            </div>
            <div class="grid gap-1.5">
                <Label for="city">City</Label>
                <Input id="city" type="text" v-model:model-value="city"/>
            </div>
            <div class="grid gap-1.5">
                <Label for="postal_code">Postal code</Label>
                <Input id="postal_code" type="text" v-model:model-value="postal_code"/>
            </div>
        </div>
        <div class="items-top flex space-x-2">            
            <Checkbox id="terms" v-model:checked="terms" />
            <Label for="terms" class="leading-snug">
                <p>By Adding, I certify that I am the legal guardian, power of attorney, representative of the custodian, or have another form of legal authority, such as express consent, for medical decisions for this patient.</p>
                <p class="mt-3">This includes the authority to view health records for this patient and assist them with accessing healthcare.
                In addition, I certify that this patient will be present with me during any consultation on their behalf with an Authorized Provider.</p>
            </Label>
        </div>
        <Button type="submit" size="lg" class="font-bold w-full mt-10 text-lg" :disabled="loader" :class="{'opacity-30 cursor-not-allowed': !terms || !name || !gender || !family || !year || !month || !day || !province || !address || !city || !postal_code}">
            <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
            Add
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </Button>
    </form>
</template>
