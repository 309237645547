import { ref, computed, watch } from 'vue'

export function useDateValidation() {
    const year = ref('')
    const month = ref('')
    const day = ref('')

    function getDaysInMonth(year: number, month: number): number {
        return new Date(year, month, 0).getDate();
    }

    const daysInMonth = computed(() => {
        if (!year.value || !month.value) return 31;
        return getDaysInMonth(parseInt(year.value), parseInt(month.value));
    });

    watch([month, year], () => {
        if (day.value) {
            const maxDays = getDaysInMonth(parseInt(year.value), parseInt(month.value));
            if (parseInt(day.value) > maxDays) {
                day.value = '';
            }
        }
    });

    function isFutureDate(year: string, month: string, day: string): boolean {
        const selectedDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return selectedDate > today;
    }

    const currentYear = new Date().getFullYear();
    const yearRange = computed(() => {
        const years: number[] = [];
        for (let i = 0; i < 100; i++) {
            const year = currentYear - i;
            years.push(year);
        }
        return years;
    });

    return {
        year,
        month,
        day,
        daysInMonth,
        isFutureDate,
        yearRange
    }
} 
