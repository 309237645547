<script setup lang="ts">
import { store } from '../store/store'
import { Button } from '@/components/ui/button'
import UserMenu from '@/components/shared/UserMenu.vue'
import { Loader2 } from 'lucide-vue-next';
import { onActivated } from 'vue';
import { useRoute } from 'vue-router';
import { getParam } from '@/lib/utils'

const route = useRoute();
const features = [
    {
        title: 'Same-Day Appointments Available',
        description: 'Access medical care when you need it most.'
    },
    {
        title: 'Free for BC Residents',
        description: 'If you have a BC Personal Health Number, our service is free.'
    },
    {
        title: 'Easy to Use',
        description: 'Book your virtual appointment and consult with licensed doctors via secure call from anywhere in Canada.'
    },
    {
        title: 'Privacy Assured',
        description: 'Your information is safe with us.'
    }
];

function book() {
    localStorage.removeItem('reschedule');
}

onActivated(() => {
    if (store.loginFlag && store.selectedPatient.pid) {
        store.link = 'who';
    }
    if (route.query.source) {
        localStorage.setItem('source', route.query.source as string);
    } else {
        localStorage.removeItem('source');
    }
})
</script>

<template>
    <div class="flex justify-between gap-10 mb-5">
        <img v-if="store.clinic.logo" :src="store.clinic.logo" :alt="store.clinic.name" class="pharmacy-logo">
        <div v-else class="text-[22px] text-blue-900 font-bold mb-4">{{ store.clinic.name }}</div>
        <UserMenu class="ml-auto"></UserMenu>
    </div>
    <h1 class="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mb-7 text-gray-900">Virtual Doctor Booking</h1>
    <ul>
        <li v-for="feature in features" :key="feature.title" class="flex mb-5">
            <i class="isax isax-tick-circle5 text-2xl text-gray-800 w-6 h-6 mr-3 shrink-0"></i>
            <div>
                <div class="font-semibold text-gray-700">{{ feature.title }}</div>
                <div class="text-sm text-gray-500 mt-1">{{ feature.description }}</div>
            </div>
        </li>
    </ul>
    <div class="mt-10 p-5 border border-gray-300 rounded-lg border-dashed text-center">
        <div class="font-base text-gray-400">Earliest Available Time</div>
        <div v-if="store.clinic.firstAvailableTime" class="mt-1 text-2xl font-bold text-gray-900">{{ store.clinic.firstAvailableTime }}</div>
        <Loader2 v-else class="w-8 h-8 animate-spin mx-auto mt-1"/>
    </div>
    <Button size="lg" class="font-bold w-full mt-5 text-lg" as-child @click="book" :class="{'opacity-50': !store.link}">
        <RouterLink :to="{name: store.link, params: { domain: getParam(route) }}">
            Book an Appointment
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </RouterLink>
    </Button>
    <div class="mt-16 flex justify-center text-gray-400 text-sm">
        Powered By
        <img src="../assets/images/Avee_Logo.svg" alt="avee logo" class="ml-1">
    </div>
</template>
