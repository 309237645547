<script lang="ts">
  export default {
    name: 'Details'
  }
</script>

<script setup lang="ts">
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area';
import { onActivated, ref, watch } from 'vue'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Loader2, FileUp, X } from 'lucide-vue-next'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { store } from '@/store/store'
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';
import { getParam } from '@/lib/utils'
import { toast } from '@/components/ui/toast/use-toast';

const loader = ref(false);
const fileLoader = ref(false);
const labelRef = ref('file');
const route = useRoute();
const router = useRouter();

const formSchema = toTypedSchema(z.object({
    note: z.string().min(20, {message: 'Please enter at least 20 characters to describe your needs for this appointment.'}).max(1000)
}))

const { handleSubmit } = useForm({
  validationSchema: formSchema,
})

const note = ref('')
const file = ref<any>('')
const agree = ref(false)

let localDetails: any = localStorage.getItem('appointment');
localDetails = JSON.parse(localDetails);
if (localDetails) {
    store.appointment = JSON.parse(JSON.stringify(localDetails));
}
if (localDetails?.note) {
    note.value = localDetails.note.split('Patient] ')[1];
}
if (localDetails?.file) {
    fileLoader.value = true;
    axios.get('/fileTransfer/get/' + localDetails.file).then((res: any) => {
        // TODO: currently the server returns empty string for this. therefore,
        // file.value won't work. this should be fixed on the server side.
        if (res.status === 'success') {
            if (res.data && res.data.name) {
                file.value = res.data.name;
            } else {
                file.value = localDetails.file;
            }
            labelRef.value = '';
        }
        fileLoader.value = false;
    });
}

let authorization = '';
const token = localStorage.getItem('token');
if (token) {
    authorization = 'Bearer ' + token;
}

const uploadFile = (event) => {
    const uploaded = event.target.files[0];
    if (uploaded.name && !fileLoader.value) {
        fileLoader.value = true;
        labelRef.value = '';
        const formData = new FormData();
        formData.append('file', uploaded)
        formData.append('pid', store.selectedPatient.pid)
        axios.post('/fileTransfer/newAppointment', formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": authorization
            }
        }).then((res: any) => {
            if (res.status == 'success') {
                file.value = uploaded.name;
                store.appointment.file = res.data.file;
                localStorage.setItem('appointment', JSON.stringify(store.appointment));
            } else {
                toast({
                    title: res.msg,
                    variant: 'destructive'
                });
            }
            fileLoader.value = false;
        }).catch((error: any) => {
            toast({
                title: error.response?.data?.msg || 'Failed to upload file',
                variant: 'destructive'
            });
            file.value = '';
            labelRef.value = 'file';
            fileLoader.value = false;
        });
    }
};

function removeFile() {
    const appointment = JSON.parse(localStorage.getItem('appointment') || '{}');
    if (appointment.file || store.appointment.file) {
        store.appointment.file = '';
        localStorage.setItem('appointment', JSON.stringify(store.appointment));
    }
    file.value = '';
    setTimeout(function () {
        labelRef.value = 'file';
    }, 50);
}

const onSubmit = handleSubmit(() => {
    if (fileLoader.value) {
        toast({
            title: "Please wait for file upload to complete",
            variant: 'destructive'
        });
        return;
    }    
    redirect();
});

function redirect() {
    loader.value = true
    setTimeout(function () {
        loader.value = false
        router.push({name: 'doctors', params: { domain: getParam(route) }})
    }, 300)
}

watch(note, (val) => {
    store.appointment.note = '[This note has been sent by the Patient] ' + val;
    localStorage.setItem('appointment', JSON.stringify(store.appointment));
})

onActivated(() => {
    agree.value = false;
    loader.value = false;
});
</script>

<template>
    <h2 class="title">Add details for the doctor</h2>
    <div class="mt-1 text-gray-600">Your information helps the doctor provide better care.</div>
    <div></div>
    <form @submit="onSubmit" class="mt-10 grid gap-7">
        <FormField v-slot="{ componentField }" v-model:model-value="note" name="note" class="w-full">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5 relative">
                        <Label for="note">What are you looking for in this appointment?</Label>
                        <Textarea id="note" class="min-h-40" v-bind="componentField"/>
                        <div class="absolute bottom-2 right-3 text-sm">
                            <span :class="{'text-red-500': note.length < 20 || note.length > 1000}">{{ note.length }}</span>/1000
                        </div>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <div class="flex flex-col gap-1.5 overflow-hidden">
            <Label>Relevant image or document</Label>
            <Label :for="labelRef"
                class="border border-dashed border-gray-500 py-3 px-4 rounded-lg text-gray-500 font-medium text-md w-full"
                :class="{'bg-gray-100 cursor-not-allowed': fileLoader, 'cursor-pointer': !fileLoader}">
                <div v-if="fileLoader" class="flex justify-center items-center">
                    <Loader2 class="animate-spin size-5 mr-2" />
                    Uploading...
                </div>
                <div v-else-if="file" class="flex justify-between items-center text-gray-900 w-full">
                    <span class="truncate">{{ file }}</span>
                    <X class="size-5 shrink-0" @click="removeFile"/>
                </div>
                <div v-else class="flex justify-center items-center">
                    <FileUp class="size-5 mr-2" />
                    Upload
                </div>
            </Label>
            <Input id="file" type="file" class="hidden" @change="uploadFile"/>
        </div>
        <div class="items-top flex space-x-2">            
            <Checkbox id="agree" v-model:checked="agree" />
            <Label for="agree" class="leading-snug">
                I have reviewed and understand all the risks, conditions, and instructions described in the 
                <Drawer>
                    <DrawerTrigger class="underline">Patient Acknowledgment and Agreement</DrawerTrigger>
                    <DrawerContent>
                        <ScrollArea class="h-screen">
                            <DrawerHeader>
                                <DrawerTitle>Please Pay Close Attention:</DrawerTitle>
                                <DrawerDescription>
                                    <ul class="list-disc ps-10 my-4">
                                        <li class="mb-3">This service is offered by Avee Health in collaboration with {{store.clinic.name}}.</li>
                                        <li class="mb-3">This is not an in-person visit; the physician will be calling you from (604) 484-0637.</li>
                                        <li class="mb-3">If you need assistance, text or leave a voicemail at (604) 484-0637.</li>
                                        <li class="mb-2">Not all cases qualify for telemedicine treatment.</li>
                                        <div>As eligibility is contingent upon adherence to regulations and ethical principles. Doctors retain the discretion to cancel appointments based on their professional judgment, and perspectives on this matter may vary among healthcare providers. Certain conditions are commonly associated with a higher likelihood of appointment cancellation. These include:</div>
                                        <ul class="list-disc ps-10 my-3">
                                            <li class="mb-3"><b>Controlled Medications:</b>Notably, substances like narcotics, benzodiazepines, and stimulants fall within this category.</li>
                                            <li class="mb-3"><b>Psychiatric Conditions:</b>Due to the complexity of psychiatric care, some cases may require in-person evaluation and management.</li>
                                            <li class="mb-3"><b>Abdominal Pain or Chest Pain:</b>These symptoms, which could be indicative of serious underlying issues, may necessitate immediate, on-site medical attention.</li>
                                            <li><b>Emergency Symptoms:</b>Conditions such as shortness of breath and suicidal tendencies may warrant immediate intervention and may be better addressed through traditional in-person healthcare services.</li>
                                        </ul>
                                    </ul>
                                </DrawerDescription>
                                <DrawerTitle>Payment Details:</DrawerTitle>
                                <DrawerDescription class="pb-20">
                                    <ul class="list-disc ps-10 my-4">
                                        <li class="mb-3"><b>BC</b> residents with a valid Personal Health Number (PHN) enjoy a <b>FREE</b> service. Provide PHN, DOB, and address for MSP coverage.</li>
                                        <li>Non-BC residents or those without a valid BC PHN must pay for the private service.</li>
                                    </ul>
                                </DrawerDescription>
                            </DrawerHeader>
                            <DrawerFooter class="fixed bottom-0 w-full backdrop-blur-sm bg-white/50">
                                <DrawerClose>
                                    <Button variant="outline">Close</Button>
                                </DrawerClose>
                            </DrawerFooter>
                        </ScrollArea>
                    </DrawerContent>
                </Drawer>
            </Label>
        </div>
        <Button 
            type="submit" 
            size="lg" 
            class="font-bold w-full mt-10 text-lg" 
            :disabled="loader || fileLoader || !agree" 
            :class="{'opacity-30 cursor-not-allowed': !agree || fileLoader}"
        >
            <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
            Done
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </Button>
    </form>
</template>
