interface EventParams {
    event_category?: string;
    event_label?: string;
    event_value?: string;
}

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void;
    }
}

export function trackEvent(
    eventName: string,
    params: EventParams
): void {
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.gtag?.('event', eventName, params);
    }
}
